import Rails from '@rails/ujs';
import initAccordion from './accordion';
import initBundleFilters from './bundle-filters';
import initModal from './modal';
import initTabs from './tabs';
import initOptionCard from './option-card';
import { initQtyPickers, getQtyPickers } from './quantity-picker';
import storePathUrl from './store-path-url';

let container, bundleId;

document.addEventListener('DOMContentLoaded', () => {
  container = document.querySelector('[data-bundle]');

  if (container) {
    bundleId = container.getAttribute("data-bundle");

    Rails.ajax({
      url: storePathUrl('/cpq/bundles'),
      type: 'POST',
      data: 'lead_product_sfid=' + bundleId,
      success: init,
    });
  }
});

const init = () => {
  const removeBtns = [...container.querySelectorAll('[data-feature-remove]')];
  const featureOptions = [...container.querySelectorAll('[data-feature-option]')];
  const addToBundleBtns = document.querySelectorAll("[data-add-option-to-bundle]");
  const pickers = getQtyPickers();

  // Init imported components
  initBundleFilters();
  initAccordion();
  initModal();
  initTabs();
  initOptionCard(enableAddToBundleButton);
  initVariants();
  initQtyPickers(pickers);

  // Update UI to reflected saved selections
  featureOptions.filter(option => option.dataset.selected).map(option => {
    const productId = option.dataset.selected;

    container.querySelector(`input[value="${productId}"]`).checked = true;
    updateFeatureOptionCard(option, productId);
  });

  // Event listener: Add product to bundle
  [...addToBundleBtns].map(button => {
    button.addEventListener("click", event => {
      const form = event.target.form;
      const selectedInput = form.querySelector('[data-single-option-radio-input]:checked');

      event.preventDefault();
      chooseFeatureOption(form, selectedInput.value);
    })
  });


  // Event listener: Remove product from bundle
  removeBtns.map(removeBtn => {
    removeBtn.addEventListener('click', event => {
      const featureOption = event.currentTarget.closest('[data-feature-option]');
      const featureId = featureOption.dataset.featureOption;
      const productId = featureOption.dataset.selected;
      const loader = featureOption.querySelector('[data-loader]');

      loader.classList.add('is-active');

      addOrUpdateProductOption(productId, featureId, 0, true, success => {
        loader.classList.remove('is-active');
        container.querySelector(`input[value="${productId}"]`).checked = false;
        updateFeatureOptionCard(featureOption, null);
      });
    });
  });

  // Event listener: Quantity change
  [...container.querySelectorAll('[data-qty-picker] input')]
    .map(input => input.addEventListener('change', updateQuantity));

  // Event listener: Select product variant
  [...container.querySelectorAll('[data-variant-option]')]
    .map(option => option.addEventListener('change', chooseProductVariant));

  document.querySelectorAll('[data-variant-group] select').forEach(selectElem => {
    selectElem.addEventListener('change', chooseProductVariant)
  })
}

function initVariants() {
  const variants = document.querySelectorAll('[data-variant-id]');
  if (variants.length === 0) return;

  const resetButton = document.querySelector('[data-variant-options-reset-button]');
  const variantOptions = [...document.querySelectorAll('[data-variant-option]')];
  const selectedOptions = returnSelectedVariantOptions(variantOptions);

  // If selected options exist upon initialisation we
  // select them so the appropriate content is shown
  if (selectedOptions.length > 0) {
    selectedOptions.map(option => {
      select(option, variantOptions, selectedOptions);
    });
  }
  else {
    restoreSessionSelections(variantOptions);
  }

  // Event listeners
  variantOptions.map(option => {
    option.addEventListener('change', event => {
      select(event.target, variantOptions);
      storeSessionSelections(variantOptions);
    });
  });

  document.querySelectorAll('[data-variant-group] select').forEach(selectElem => {
    selectElem.addEventListener('change', event => {
      const option = [...event.target.querySelectorAll('option')].filter(o => o.selected)[0];

      select(option, variantOptions);
      storeSessionSelections(variantOptions);
    })
  })

  if (resetButton) {
    resetButton.addEventListener('click', () => {
      reset(variantOptions);
    });
  }

  //Temporarily show all hidden content from old selector
  [...document.querySelectorAll('[data-variant-selector]')].map(node => {
    node.style.display = 'block';
  });

}

export const addOrUpdateProductOption = (productId, featureId, qty, isSingleOption, onSuccessCallback) => {
  const button =  document.querySelector('[data-bundle-add-to-cart]');
  const payload = generatePayload(productId, featureId, qty, isSingleOption);

  if (button) {
    button.disabled = true;
    button.value = "Updating...";
  }

  Rails.ajax({
    url: storePathUrl('/cpq/bundles/' + bundleId + '/product_options/' + productId),
    type: 'PATCH',
    data: payload,
    success: () => onSuccessCallback(),
  });
}

function chooseFeatureOption(form, productId) {
  const isOptional = form.dataset.optional == "true";
  const featureId = form.id;
  const featureOptionCard = container.querySelector(`[data-feature-option="${featureId}"]`);
  const prevSelectedProductId = featureOptionCard.dataset.selected;
  const loader = featureOptionCard.querySelector('[data-loader]');

  loader.classList.add('is-active');

  // If a product is optional we must first remove the existing selection
  // before adding a new one.
  // Note: to simplify the logic here this could be handled in the backend
  if (isOptional && prevSelectedProductId) {
    addOrUpdateProductOption(prevSelectedProductId, featureId, 0, true, success => {
      addOrUpdateProductOption(productId, featureId, 1, true, success => {
        loader.classList.remove('is-active');
        updateFeatureOptionCard(featureOptionCard, productId);
      });
    });
  } else {
    addOrUpdateProductOption(productId, featureId, 1, true, success => {
      loader.classList.remove('is-active');
      updateFeatureOptionCard(featureOptionCard, productId);
    });
  }
}

function updateFeatureOptionCard(card, productId) {
  const img = card.querySelector('[data-feature-img]');
  const headline = card.querySelector('[data-feature-headline]');
  const subheadline = card.querySelector('[data-feature-subheadline]');
  const remove = card.querySelector('[data-feature-remove]');
  const trigger = card.querySelector('[data-modal-trigger]');

  let data = {
    headline: headline.dataset.featureHeadline,
    subheadline: subheadline.dataset.featureSubheadline,
    imageUrl: img ? img.dataset.featureImg : null
  }

  card.dataset.selected = "";

  if (productId) {
    const input = document.querySelector(`input[value="${productId}"]`);
    const productCard = input.form.querySelector(`[data-single-option="${input.value}"]`);
    const optionCard = input.form.querySelector(`[data-product-option-sfid="${input.dataset.optionId}"]`);
    const subHeadline = productCard.querySelector('[data-single-option-subheading]');
    const image = optionCard.querySelector('[data-single-option-image]');

    let subHeadlineText, imageUrl;

    if (subHeadline) subHeadlineText = subHeadline.innerHTML;
    if (image) imageUrl = image.src;

    data = {
      headline: returnHeadline(optionCard, productCard),
      subheadline: subHeadlineText,
      imageUrl: imageUrl
    };

    card.dataset.selected = productId;
    trigger.querySelector('span').innerText = trigger.dataset.edit;

    if (data.imageUrl) {
      img.src = data.imageUrl;
      img.classList.remove('sc-hide');
    } else img.classList.add('sc-hide');

    if (remove) {
      remove.classList.remove('sc-hide');
      trigger.classList.add('sc-hide');
    }
  } else {
    img.classList.add('sc-hide');
    trigger.querySelector('span').innerText = trigger.dataset.add;
    if (remove) {
      remove.classList.add('sc-hide');
      trigger.classList.remove('sc-hide');
    }
  }

  headline.innerText = data.headline;
  subheadline.innerHTML = data.subheadline;
}

function returnHeadline(optionCard, productCard) {
  return optionCard.querySelector('[data-option-card-subset]') ?
    productCard.querySelector('[data-single-option-heading]').innerText :
    optionCard.querySelector('[data-single-option-heading]').innerText;
}

function chooseProductVariant(e) {
  const id = e.target.form.id;
  const featureId = e.target.form.querySelector('[data-feature]').value;
  const price = e.target.getAttribute('data-price');
  const priceEl = e.target.form.querySelector('[data-bundle-product-option-price]');
  const clearOption = document.querySelector(`[data-clear-product-option="${id}]"`);
  const payload = generateVariantPayload(e.target.name, e.target.value, featureId);
  const variantSelector = e.target.value;

  if (clearOption) {
    clearOption.checked = false;
  }

  Rails.ajax({
    url: storePathUrl('/cpq/bundles/' + bundleId + '/product_options/' + id),
    type: 'PATCH',
    data: payload,
    success: () => {
      if (priceEl) {
        priceEl.innerText = price;
      }
    }
  });
}

function updateQuantity(e) {
  const featureId = e.target.form.id;
  const productId = e.target.form.dataset.productOptionSfid;
  const qty = e.target.form.querySelector('[data-qty-picker] input').value;

  if (productId) {
    addOrUpdateProductOption(productId, featureId, qty, false, () => {});
  }
}

function enableAddToBundleButton(event) {
  const button = event.currentTarget.closest('.SC-Modal').querySelector('[data-add-option-to-bundle]');

  if (button.hasAttribute('disabled')) {
    button.removeAttribute('disabled');
  }
}

function generatePayload(optionId, featureId, qty, isSingleOption) {
  const payload = "product_option[sfid]=" + optionId +
                  "&product_option[quantity]=" + qty +
                  "&product_option[feature]=" + featureId;

  if (isSingleOption) {
    return payload + "&product_option[single_option]=1";
  }

  return payload;
}

function generateVariantPayload(name, value, featureId) {
  // Because we are re-using the `variant_options` partial, we need to massage
  // the data slightly to match what the CPQ::ProductOptionsController expects
  if (name.startsWith('variant[')) {
    const variants = name.replace('variant[', 'variants][');

    return `product_option[${variants}[${value}][quantity]=1&product_option[feature]=${featureId}`;
  }

  else {
    const payload = name.includes('quantity') ?
                    `${name}=${value}` :
                    `${name}[${value}][quantity]=1`;

    // to avoid duplicate names, inputs have the product option sfid as a prefix,
    // here we strip out the sfid (and its trailing underscore) when constructing the payload
    return payload.substring(id.length + 1) + "&product_option[feature]=" + featureId;
  }
}





function returnSelectedVariantOptions(variantOptions) {
  return variantOptions
    .filter(opt => opt.checked || opt.selected)
    .sort((a, b) => {
      if (a.dataset.type < b.dataset.type) return -1;
      if (a.dataset.type > b.dataset.type) return 1;
      return 0;
    });
}

function select(option, options) {
  disableOrEnableVariantOptions(option, options);

  const variants = document.querySelectorAll('[data-variant-id]');
  const leadProduct = document.querySelector('[data-lead-product]');
  const opts = [...leadProduct.querySelectorAll('[data-variant-option]')];
  const selectedOptions = returnSelectedVariantOptions(opts);
  const requiredSelections = document.querySelectorAll('[data-variant-group]').length;
  const selectedVariant = returnSelectedVariantAsString(selectedOptions, requiredSelections);
  const leadProductImage = document.querySelector('[data-lead-product-image]');

  [...variants].map(variant => {
    if (variant.dataset.variantId === selectedVariant) {
      variant.classList.remove('is-hidden');
      if (variant.getAttribute('data-variant-image-src') && leadProductImage) {
        leadProductImage.src = variant.getAttribute('data-variant-image-src');
      }
      return;
    }
    variant.classList.add('is-hidden');
  });
}

function disableOrEnableVariantOptions(option, variantOptions) {
  const allowedOptions = option.dataset.variantAllowedOptions;
  const optionType = option.dataset.type;

  if (!allowedOptions || allowedOptions === "{}") return;

  [...document.querySelectorAll('[data-variant-option]')].map(group => {
    const groupType = group.dataset.type;
    const optionsInGroup = variantOptions.filter(o => o.dataset.type === groupType);

    if (groupType === optionType) return;
    optionsInGroup.map(option => {
      option.disabled = !JSON.parse(allowedOptions)[groupType].includes(option.value);
    });
  });
}

function returnSelectedVariantAsString(selectedOptions, requiredSelections) {
  if (selectedOptions.length === requiredSelections) {
    return selectedOptions.map(option => option.value).join(' ');
  }
}

function restoreSessionSelections(variantOptions) {
  const selectorNode = document.querySelector('[data-variant-option-selector]');
  if (!selectorNode) { return; }
  const masterId = selectorNode.dataset.masterId;
  const selectedVariantIds = JSON.parse(window.sessionStorage.getItem(`${masterId}:selected-variants`));
  selectSelectedIds(variantOptions, selectedVariantIds);
}

// Selected IDs is something like ['color-multi', 'size-37']
function selectSelectedIds(variantOptions, selectedVariantIds) {
  let selectedIds = null;

  // Parse the array and clean it of nulls and empty strings if they exist
  if (Array.isArray(selectedVariantIds)) {
    selectedIds = selectedVariantIds.filter(x => x);
  }

  if (selectedIds) {
    selectedIds.forEach(id => {
      var option = document.querySelector(`#${id}`);

      if (!option) return

      if (option.tagName === 'OPTION') {
        option.selected = true;
        select(option, variantOptions);
      } else {
        option.checked = true;
        select(option, variantOptions);
      }
    });
  }
}

function storeSessionSelections(variantOptions) {
  const selectorNode = document.querySelector('[data-variant-option-selector]');
  const masterId = selectorNode.dataset.masterId;
  const selectedIds = returnSelectedVariantOptions(variantOptions).map(elem => elem.id);
  window.sessionStorage.setItem(`${masterId}:selected-variants`, JSON.stringify(selectedIds));
}

function reset(variantOptions) {
  // Unselect all the options and checkboxes
  variantOptions.map(optionInput => {
    if (optionInput.tagName === 'OPTION') optionInput.selected = false;
    if (optionInput.tagName === 'INPUT') optionInput.checked = false;
    optionInput.disabled = false;
  });
  // Reset all select boxes to the null state
  [...document.querySelectorAll('[data-variant-group] select')].map(selectInput => {
    [...selectInput.querySelectorAll('option[disabled]')].map(optionInput => {
      optionInput.selected = true;
    });
  });
  disableAddToCart();
  showOrHideVariantContent();
  clearSessionSelections();
}

function disableAddToCart() {
  [...document.querySelectorAll('[data-bundle-add-to-cart]')].map(selectInput => {
    selectInput.disabled = 'disabled';
  })
}

function showOrHideVariantContent(selectedVariant) {
  [...document.querySelectorAll('[data-variant-id]')].map(node => {
    const isMaster = !node.dataset.variantId;
    const isSelected = isMaster ?
      selectedVariant === undefined :
      node.dataset.variantId === selectedVariant;

    node.classList.toggle('is-hidden', !isSelected);
  });
}

function clearSessionSelections() {
  const selectorNode = document.querySelector('[data-variant-option-selector]');
  const masterId = selectorNode.dataset.masterId;
  window.sessionStorage.removeItem(`${masterId}:selected-variants`);
}
