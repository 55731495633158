document.addEventListener('DOMContentLoaded', init)

function init() {
  [...document.querySelectorAll('[data-show-more-next-page]')].map(element => {
    onClickLoadMoreProducts(element)
  })
}

function onClickLoadMoreProducts(element) {
  displayLoadMoreButton()
  element.addEventListener('click', function (event) {
    event.preventDefault()
    loadMoreProducts(element)
  })
}

async function loadMoreProducts(element) {
  // Find nearby element with data-show-more attribute where new items will be appended
  const gridElement = document.querySelector('[data-show-more]')

  // Convert element.dataset.nextPage to integer and add 1
  const loadMoreButton = element
  const nextPageNumber = parseInt(loadMoreButton.dataset.showMoreNextPage) + 1

  // Build URL with new page number page=${nextPageNumber}
  let nextPageUrl = new URL(window.location, window.location)
  let pageParam = element.dataset.showMoreParam || 'page'

  nextPageUrl.searchParams.set(pageParam, nextPageNumber)

  const response = await fetch(nextPageUrl, {headers: {'Accept': 'application/json'}})
  if (!response.ok) {
    console.error(response.statusText)
    return
  }
  const htmlContent = await response.text();

  gridElement.insertAdjacentHTML('beforeend', htmlContent)

  element.dataset.showMoreNextPage = nextPageNumber
  displayLoadMoreButton()
}

function displayLoadMoreButton() {
  const loadMoreButton = document.querySelector('[data-show-more-next-page]')

  if (loadMoreButton.dataset.showMoreTotalPages !== undefined && loadMoreButton.dataset.showMoreTotalPages !== '') {
    const currentPageNumber = parseInt(loadMoreButton.dataset.showMoreNextPage)
    const totalPages = parseInt(loadMoreButton.dataset.showMoreTotalPages)

    if (currentPageNumber >= totalPages) {
      loadMoreButton.classList.add('hidden', 'is-hidden')
    } else {
      loadMoreButton.classList.remove('hidden', 'is-hidden')
    }
  }
}
