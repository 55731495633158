document.addEventListener('DOMContentLoaded', init);

function init() {
  [...document.querySelectorAll('a[data-search-nav-source]')].map(linkElement => {
    setupNavLink(linkElement);
  })
}

function setupNavLink(linkElement) {
  const source = linkElement.dataset.searchNavSource;
  const linkHref = linkElement.getAttribute('href');
  // add/replace ?source=... in the link href using URL lib
  const url = new URL(linkHref, window.location.origin);
  url.searchParams.set('source', source);
  linkElement.setAttribute('href', url.href);
}
