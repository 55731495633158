// Deprecated: Use menu.js

import hoverintent from 'hoverintent/dist/hoverintent.min'
import { largeAndUp } from './viewport'

let prevNav

document.addEventListener('DOMContentLoaded', init)


/**
 * Initialise event listeners
 */

function init() {
  const triggers = [...document.querySelectorAll('[data-nav-trigger]')]

  if (largeAndUp()) {
    triggers.map(trigger => {
      hoverintent(trigger.parentNode, show, hide)
    })
  } else {
    triggers.map(trigger => {
      trigger.addEventListener('click', open)
    })
  }

  [...document.querySelectorAll('[data-nav-close]')].map(btn => {
    btn.addEventListener('click', close)
  })
}


/**
 * Desktop event handlers
 */

function show(e) {
  const rootNav = document.querySelector('[data-nav=root]')
  const navTrigger = e.target.parentNode
  const nav = navTrigger.querySelector('[data-nav]') || rootNav

  // Unset previously active
  if (prevNav) prevNav.classList.remove('is-active')
  prevNav = nav

  e.target.classList.add('is-active')
  nav.classList.add('is-active')
  navTrigger.classList.add('is-active')
}

function hide(e) {
  prevNav.classList.remove('is-active')
  e.target.parentNode.classList.remove('is-active')
  e.target.parentNode.querySelector('[data-nav-trigger]')
    .classList.remove('is-active')
}


/**
 * Handheld event handlers
 */

function open(event) {
  const navTrigger = event.target
  const navId = navTrigger.getAttribute('data-nav-trigger')
  const nav = document.querySelector('[data-nav="' + navId + '"]')

  if (nav) {
    event.preventDefault()
    prevNav = nav
    nav.classList.add('is-active')
    document.querySelector('body').style.overflow = "hidden"
  }
}

function close(event) {
  const prevNavId = event.target.getAttribute('data-nav-close')

  prevNav.classList.remove('is-active')

  if (prevNavId) {
    prevNav = document.querySelector('[data-nav="' + prevNavId + '"]')
  } else {
    prevNav = null
    document.querySelector('body').style.overflow = 'visible'
  }
}
