// This file is being required on top of application.js
// Frontend dependencies e.g. jquery will not be accessible from here

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

window.StoreConnect = window.StoreConnect || {};
window.StoreConnect.configure = function (ENV) {
  if (ENV.BUGSNAG_KEY) {
    // Attaching to window so we can easily debug, and test as well
    window.bugsnagClient = Bugsnag;
    Bugsnag.start({
      apiKey: ENV.BUGSNAG_KEY,
      appVersion: ENV.STORE_CONNECT_VERSION,
      notifyReleaseStages: ['production', 'staging'],
      releaseStage: ENV.BUGSNAG_RELEASE_STAGE,
      appType: 'client',
      plugins: [new BugsnagPluginReact()]
    });
  } else {
    throw new Error("Failed to configure Bugsnag, missing BUGSNAG_KEY");
  }
};
