import { addOrUpdateProductOption } from './bundle';

let triggers, inputs, prevSubset;

function init(onChangeCallback) {
  triggers = [...document.querySelectorAll('[data-option-card-trigger]')];
  inputs = [...document.querySelectorAll('[data-single-option-radio-input]')];

  inputs.map(input => {
    input.addEventListener('change', event => {
      const id = event.currentTarget.value;
      const option = document.querySelector(`[data-single-option="${id}"]`);
      const qtyInputs = [...document.querySelectorAll('[data-qty-picker] input')];
      const input = option.querySelector('[data-qty-picker] input');

      if (input) {
        qtyInputs.map(input => input.value = 0);
        input.value = 1;

        addOrUpdateProductOption(input.id, input.name + "=" + input.value);
      }
    });
  });

  triggers.map(trigger => {
    trigger.addEventListener('click', (event) => {
      choose(event, onChangeCallback);
    });
  });
}

function choose(event, onChangeCallback) {
  const choice = event.currentTarget.parentNode;
  const subset = choice.querySelector('[data-option-card-subset]');

  // We manually handle checking inputs
  // to avoid multiple events firing
  event.preventDefault();

  // Unselect other options
  triggers.map(trigger => {
    trigger.parentNode.classList.remove('is-selected')
  });

  // If there is a previously opened subset of options
  // we close it when a new choice is made
  if (prevSubset && !prevSubset.contains(choice)) {
    prevSubset.classList.remove('is-active');
    prevSubset.parentNode.classList.remove('is-active');
  }

  // If our chosen option has a subset of options
  // we open the subset
  if (subset) {
    subset.classList.add('is-active');
    subset.parentNode.classList.add('is-active');
    prevSubset = subset;


  // Otherwise, we select the option
  } else {
    const input = choice.querySelector('input[type=radio]');

    choice.classList.add('is-selected');
    input.checked = true;
    triggerChangeEvent(input);
  }

  onChangeCallback(event);
}

function triggerChangeEvent(input) {
  const event = document.createEvent("HTMLEvents");

  event.initEvent("change", false, true);
  input.dispatchEvent(event);
}

export default init;
