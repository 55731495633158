const Cart = (() => {
  const TEASE_INTERVAL = 7000;

  $(function() {
    if (document.querySelector('[data-cart-tease]')) {
      Cart.tease();
    }
  });

  function closeCartIfClicked(e) {
    let target = e.target,
        parents = [];

    while (target) {
      parents.unshift(target);
      target = target.parentElement;
    }

    parents.map(parent => {
      if (!parent.hasAttribute('data-nav=cart')) {
        Cart.close();
      }
    });
  }

  return {
    open: () => {
      const cart = document.querySelector('[data-nav=cart]');
      if (cart) { cart.classList.add('is-active'); }
    },

    close: () => {
      document.querySelector('body').removeEventListener('click', closeCartIfClicked);
      const cart = document.querySelector('[data-nav=cart]');
      if (cart) { cart.classList.remove('is-active'); }
    },

    tease: () => {
      const cart = document.querySelector('[data-nav=cart]');

      if (cart) {
        Cart.open();
        setTimeout(() => cart.classList.remove('is-active'), TEASE_INTERVAL);
      }
      document.querySelector('body').addEventListener('click', closeCartIfClicked);
    }
  }
})();

window.StoreConnect = window.StoreConnect || {};
window.StoreConnect.Cart = Cart;
