import { tns } from "tiny-slider/src/tiny-slider"

const DEFAULT_PARAMS = {
  autoplay: true,
  autoplayHoverPause: true,
  autoplayButtonOutput: false,
  arrowKeys: true,
  autoHeight: true,
  center: true,
  controlsText: ['', '']
}

let slideshows = []

document.addEventListener('DOMContentLoaded', init)

export default function init(params) {
  if (document.querySelectorAll('[data-slideshow]')) {
    [...document.querySelectorAll('[data-slideshow]')].map(slideshowEl => {
      const container = {container: '[data-slideshow=' + slideshowEl.getAttribute('data-slideshow') + ']'}
      const slideshow = tns({...DEFAULT_PARAMS, ...params, ...container})

      let resizeId

      slideshows.push(slideshowEl)
      window.addEventListener('resize', () => {
        clearTimeout(resizeId)
        resizeId = setTimeout(() => slideshow.updateSliderHeight(), 300)
      })
    })
  }
}
