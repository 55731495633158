import moment from "moment";

window.StoreConnect = window.StoreConnect || {};

window.StoreConnect.formatDateTime = function(value, targetInputId) {
  const date = moment(value);
  const targetInput = document.getElementById(targetInputId)

  if (date.isValid() && targetInput) {
    targetInput.value = date.toISOString();
  }
};
