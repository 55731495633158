window.StoreConnectUI = window.StoreConnectUI || {};

window.StoreConnectUI.PricePicker = (function () {
  let picker, prevPicker;

  function open() {
    const list = picker.querySelector('[data-price-picker-list]');

    prevPicker = picker;
    list.classList.add('is-active');
    list.setAttribute('aria-hidden', false);
  }

  function close(picker) {
    const list = picker.querySelector('[data-price-picker-list]');

    list.classList.remove('is-active');
    list.setAttribute('aria-hidden', true);
  }

  function closeIfClickElsewhere(e) {
    if (!picker.contains(e.target)) { close(picker); }
  }

  return {
    init: (e) => {
      const parent = e.target.parentNode,
            buttons = [...parent.querySelectorAll('[data-price-picker-btn]')];

      picker = parent;

      if (prevPicker) {close(prevPicker);}

      open(picker);
      buttons.map(button => button.addEventListener('click', PricePicker.set));
      document.addEventListener('click', closeIfClickElsewhere);
    },

    set: (e) => {
      const input = picker.querySelector('[data-price-picker-input]');

      input.value = e.target.value;
      picker.querySelector('[data-price-picker-value]').innerText = e.target.innerText;
      if (input.value) input.dispatchEvent(new Event('change'));
      close(picker);
    },

    manualEntry: () => {
      const input = picker.querySelector('[data-price-picker-input]');

      input.classList.add('is-active')
      input.focus();
      picker.querySelector('[data-price-picker-trigger]').classList.add('is-hidden');
      close(picker);
    }
  }
})();

// Deprecated:
// Projects using webpacker should import functions
// directly as ES6 modules.
const PricePicker = window.StoreConnectUI.PricePicker;
