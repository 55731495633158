import 'ion-rangeslider';
import { mediumAndUp } from './viewport';

document.addEventListener('DOMContentLoaded', init);

function init() {
  [...document.querySelectorAll('[data-js-range-slider]')].map(element => {
    setupPriceSlider(element);
  })
}

function setupPriceSlider(element) {
  $(element).ionRangeSlider({
    onFinish: function (data) {
      // 'price' or 'points'
      const jsRangeMin = element.parentElement.querySelector('[data-js-range-min]');
      const jsRangeMax = element.parentElement.querySelector('[data-js-range-max]');

      if (data.min != data.from) {
        jsRangeMin.value = data.from;
      } else {
        // Removing the name field from these attributes prevents them from polluting the URL
        // to make a cleaner, copyable, understandable URL field and improve search results
        jsRangeMin.value = '';
        jsRangeMin.removeAttribute('name');
      }

      if (data.max != data.to) {
        jsRangeMax.value = data.to;
      } else {
        // Removing the name field from these attributes prevents them from polluting the URL
        // to make a cleaner, copyable, understandable URL field and improve search results
        jsRangeMax.value = '';
        jsRangeMax.removeAttribute('name');
      }

      if (!mediumAndUp()) { return; }
      const form = element.closest('form');
      form.submit();
    },
  });
}
