document.addEventListener('DOMContentLoaded', init)

export default function init() {
  [...document.querySelectorAll('[data-accordion-trigger]')].map(trigger => {
    trigger.addEventListener('click', toggleActiveState)
  })
}

function toggleActiveState(e) {
  const parent = e.currentTarget.parentNode
  const target = parent.querySelector('[data-accordion-target]')
  const icons = parent.querySelectorAll('[data-accordion-icon]')

  if (target.getAttribute('aria-expanded') === 'true') {
    target.setAttribute('aria-expanded', 'false')
  } else {
    target.setAttribute('aria-expanded', 'true')
  }

  [...icons].map(icon => icon.classList.toggle('is-active'))
  parent.classList.toggle('is-active')
  target.classList.toggle('is-expanded')
}
