$(function() {
  // Note: In future store this in local storage
  var ProductComparison = [];

  $('[data-js-compare-add]').on('change', function(e) {
    updateSelectedProducts(e.target);
    $('[data-js-compare]').toggleClass('is-hidden', no_selection());
    $('[data-js-compare-trigger]').attr('disabled', nothing_to_compare());
    $('[data-js-compare-count]').text(ProductComparison.length);
  });

  $('[data-js-compare-trigger]').on('click', function(e) {
    var payload = {
      category: e.target.getAttribute('data-js-compare-trigger'),
      ids: ProductComparison
    }

    $.get('/product_comparison', payload, function(response) {
      $('[data-js-screen]').removeClass('is-hidden')
      $('[data-js-screen-body]').html(response);
    });
  });

  $('[data-js-compare-cancel]').on('click', function() {
    ProductComparison = [];
    $('[data-js-compare]').addClass('is-hidden');
    $('[data-js-compare-add]').prop('checked', false);
  });

  $('[data-js-screen-close]').on('click', function() {
    $('[data-js-screen]').addClass('is-hidden');
  });

  function updateSelectedProducts(product) {
    // Add product to state
    if (product.checked) {
      ProductComparison.push(product.id);
      return;
    }

    // Remove product from state
    ProductComparison = ProductComparison.filter(function(productId) {
      return productId !== product.id;
    });
  }

  function no_selection() {
    return ProductComparison.length < 1;
  }

  function nothing_to_compare() {
    return ProductComparison.length < 2;
  }
});
