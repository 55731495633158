document.addEventListener('DOMContentLoaded', initPtsToggles);

export function initPtsToggles() {
  [...document.querySelectorAll('[data-pts-toggle] input')].map(toggle => {
    toggle.addEventListener('change', () => changeToggle(toggle));
  });
}

function changeToggle(toggle) {
  toggle.form.requestSubmit();
}
