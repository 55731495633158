import Rails from '@rails/ujs';
import storePathUrl from './store-path-url';

const CustomerMetadata = (() => {
  document.addEventListener('DOMContentLoaded', function() {
    if (document.querySelector('[data-customer-metadata]')) {
      updateCart();
    }
  });

  const timeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  const screenResolution = () => {
    return `${window.screen.width * window.devicePixelRatio} X ${window.screen.height * window.devicePixelRatio}`;
  }

  const language = () => {
    return window.navigator.language;
  }

  const updateCart = () => {
    const data = {
      timezone: timeZone(),
      language: language(),
      screen_resolution: screenResolution()
    }
    Rails.ajax({
      url: storePathUrl("/cart/customer_metadata"),
      type: 'POST',
      dataType: 'json',
      data: new URLSearchParams(data).toString(),
    });
  }
})();
