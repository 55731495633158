import Rails from '@rails/ujs';
import 'select2'
import { buildUrl } from "./helpers";

window.StoreConnect = window.StoreConnect || {};

window.StoreConnect.address = function (options) {
  const state_id = options['state_id'];
  const country_select = document.getElementById(options['country_id']);
  const address_type = options['address_type']
  const billing_same_as_shipping_element = document.getElementById(options['billing_same_id']);

  updateStates(country_select.value);

  country_select.addEventListener('change', function (event) {
    const countryId = event.target.value;
    updateStates(countryId);
    updateRequired(countryId);
  });

  if (billing_same_as_shipping_element) {
    billing_same_as_shipping_element.addEventListener("change", () => {
      updateRequired(country_select.value);
    });
  }

  updateRequired(country_select.value);

  // ====== ***** METHODS ***** ===================================================================== //
  function billing_same_as_shipping() {
    return billing_same_as_shipping_element ? billing_same_as_shipping_element.checked : false;
  }

  function updateRequiredFields(fields) {
    [...document.querySelectorAll('[data-required-fields-form] input, [data-required-fields-form] select')].map(input => {
      const name = input.name;
      if (name && input.name.includes(address_type)) {
        let required = fields.includes(name)
        if (address_type == "billing" && billing_same_as_shipping()) {
          required = false;
        }

        // If name=shipping_address_lines[] then only check if first one is required
        if (name.includes("address_lines")) {
          const form = input.form;
          const index = Array.from(form.querySelectorAll(`input[name="${input.name}"]`)).indexOf(input)
          if (index == 0) {
            required = fields.includes(name.replace(`[]`, ``))
          }
        }

        if (required) {
          input.required = true;
          input.dataset.required = true;
          if (input.labels) {
            input.labels.forEach(label => {
              label.dataset.required = true;
            }
            )
          }
        } else {
          input.required = false;
          delete input.dataset.required;
          if (input.labels) {
            input.labels.forEach(label => {
              delete label.dataset.required;
            });
          }
        }
      }
    });
  }

  function updateRequired(country_id) {
    // NOTE: condition to handle if no store path is set
    const url = buildUrl(options.current_store_path, "/checkout/required_fields");
    Rails.ajax({
      url: url,
      type: 'POST',
      data: new URLSearchParams({ country_id: country_id, billing_same_as_shipping: billing_same_as_shipping() }).toString(),
      success: function (data) { updateRequiredFields(data) }
    });
  }

  function updateStates(id) {
    if (id === "") { return (false) };
    // NOTE: condition to handle if no store path is set
    const url = buildUrl(options.current_store_path, "/checkout/find_states");
    Rails.ajax({
      url: url,
      type: 'POST',
      data: new URLSearchParams({ country_id: id }).toString(),
      success: function (data) { buildStatesDropdown(data) }
    });
  }

  function buildStatesDropdown(data) {
    const state = document.getElementById(state_id)
    const selected_value = state.getAttribute("data-selected")

    state.options.length = 0
    data.unshift(['', state.getAttribute("placeholder")])

    data.forEach(element => {
      const newOption = document.createElement('option');
      const optionText = document.createTextNode(element[1]);
      newOption.appendChild(optionText);
      newOption.setAttribute('value', element[0]);
      if (element[0] === selected_value) {
        newOption.setAttribute('selected', true);
      }
      state.appendChild(newOption);
    });
  }
}

window.StoreConnect.address_autocomplete = function (options) {
  const autocomplete = document.querySelector('[data-address-autocomplete]')

  if (autocomplete) {
    const container = autocomplete.closest('[data-address-autocomplete-container]')
    const form = autocomplete.closest("form")

    // NOTE: condition to handle if no store path is set
    const url = buildUrl(options.current_store_path, "/checkout/valid_addresses");
    $(autocomplete).select2({
      placeholder: autocomplete.getAttribute('data-address-autocomplete-placeholder'),
      width: 'style',
      ajax: {
        url: url,
        dataType: 'json'
      },
    });

    $(autocomplete).on('select2:select', event => {
      const address = event.params.data

      form.querySelector('[autocomplete~="address-line1"]').value = address['address_1']
      form.querySelector('[autocomplete~="address-level2"]').value = address['city']
      form.querySelector('[autocomplete~="address-level1"]').value = address['state']
      form.querySelector('[autocomplete~="postal-code"]').value = address['postal_code']
    });

    if (options['supported_countries'].length > 0) {
      const country_field = form.querySelector('[autocomplete~="country"]')

      if (country_field) {
        country_field.addEventListener('change', event => {
          const target = event.target

          if (target.value == 'AU') {
            container.classList.remove("sc-hide")
          } else {
            container.classList.add("sc-hide")
          }
        });
        country_field.value = options['supported_countries'][0] || "AU"
      }
    }
  }
}
